@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --shadow-color: rgba(0,0,0, 0.03);

  --small-shadow: 0.3px 0.5px 0.7px var(--shadow-color),
    0.4px 0.8px 1px -1.2px var(--shadow-color),
    1px 2px 2.5px -2.5px var(--shadow-color);

  --shadow-medium: 0.3px 0.5px 0.7px var(--shadow-color),
    0.8px 1.6px 2px -0.8px var(--shadow-color),
    2.1px 4.1px 5.2px -1.7px var(--shadow-color),
    5px 10px 12.6px -2.5px var(--shadow-color);

  --big-shadow: 0.3px 0.5px 0.7px var(--shadow-color),
    1.5px 2.9px 3.7px -0.4px var(--shadow-color),
    2.7px 5.4px 6.8px -0.7px var(--shadow-color),
    4.5px 8.9px 11.2px -1.1px var(--shadow-color),
    7.1px 14.3px 18px -1.4px var(--shadow-color),
    11.2px 22.3px 28.1px -1.8px var(--shadow-color),
    17px 33.9px 42.7px -2.1px var(--shadow-color),
    25px 50px 62.9px -2.5px var(--shadow-color);
}

body {
  touch-action: none;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root {
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
